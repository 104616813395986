<template>

  <div class="container">
    <div class="card text-color">
      <h1>Connect To Database</h1>

      <div class="section">
        <div class="redis-url-container">
          <p class="redis-url" @click="copyToClipboard">{{ hiddenRedisUrl }}</p>
          <button v-if="showNotification" class="copy-button" @click="copyToClipboard">Redis URL Copied!</button>
          <button v-else class="copy-button" @click="copyToClipboard">Copy</button>
        </div>
      </div>


      <div class="section table-responsive">
        <table class="custom-table">
          <thead>
            <tr>
              <th>Region</th>
              <th>Endpoint</th>
              <th>
                Password
                <span v-if="!hidePassword" class="eye-icon" @click="togglePasswordVisibility">&#x1F512;</span>
                <span v-else class="eye-icon" @click="togglePasswordVisibility">&#x1F440;</span>
              </th>
              <th>Port</th>
              <th>TLS/SSL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ region }}</td>
              <td>{{ endpoint }}</td>
              <td v-if="!hidePassword" class="password-field">
                {{ password }}

              </td>
              <td v-else class="password-field">
                **********************
              </td>
              <td>{{ port }}</td>
              <td>{{ tlsEnabled ? 'Enabled' : 'Disabled' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="section tabs-container">
        <div
          v-for="(tab, index) in tabItem"
          :key="index"
          @click="changeTab(index)"
          :class="{ active: tabValue === index }"
        >
          {{ tab }}
        </div>
      </div>

      <div class="">
        <div class="">
          <div v-if="selectedLanguage === 'Shell'" class="code-block">
            <pre>
              <code class="language-shell">
#!/bin/bash

# Define variables
REDIS_HOST="{{ endpoint }}"
REDIS_PORT="{{ port }}"
REDIS_PASSWORD="{{ password }}"

# Connect to Redis using redis-cli
redis-cli -h $REDIS_HOST -p $REDIS_PORT -a $REDIS_PASSWORD set foo bar
              </code>
            </pre>
          </div>
          <div v-if="selectedLanguage === 'Js'" >
            <CodeBlock
              :code="jsCode"
              label=""
              :prismjs="true"
              lang="javascript"
              :theme="codeBlockTheme"
            />
          </div>
          <div v-if="selectedLanguage === 'Php'" class="code-block">
            <CodeBlock
              :code="phpCode"
              lang="php"
              prismjs
              :tabs="false"
              :theme="codeBlockTheme"
            />
          </div>
          <div v-if="selectedLanguage === 'Python'" class="code-block">
            <CodeBlock
              :code="pythonCode"
              lang="python"
              prismjs
              :tabs="false"
              :theme="codeBlockTheme"
            />
          </div>
          <div v-if="selectedLanguage === 'Go'" class="code-block">
            <CodeBlock
              :code="goCode"
              lang="go"
              prismjs
              :tabs="false"
              :theme="codeBlockTheme"
            />
          </div>
          <div v-if="selectedLanguage === 'Ruby'" class="code-block">
            <CodeBlock
              :code="rubyCode"
              lang="ruby"
              prismjs
              :tabs="false"
              :theme="codeBlockTheme"
            />
          </div>

          <div v-if="selectedLanguage === 'Java'" class="code-block">
            <CodeBlock
              :code="javaCode"
              lang="java"
              prismjs
              :tabs="false"
              :theme="codeBlockTheme"
            />
          </div>

          <!-- Add other language snippets here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrometheusDriver } from "prometheus-query";
import store from "@/store";
import axios from "axios";
import {useGlobalConfig} from "vuestic-ui";
import CodeBlock from 'vue3-code-block';
// import 'prismjs/themes/prism-coy.css';
import 'prismjs/themes/prism-tomorrow.css';
// import 'prismjs/themes/prism-okaidia.css'
// import 'prismjs/themes/prism-dark.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-java';
import 'highlight.js'


export default {
  data() {
    return {
      jsCode: `
const connectToRedis = async () => {
  try {
    const Redis = require("ioredis");
    const client = new Redis({
      host: "{{ endpoint }}",
      port: {{ port }},
      password: "{{ password }}",
      tls: {{ tlsEnabled ? 'true' : 'false' }},
    });

    await client.set('foo', 'bar');
    // You can perform other Redis operations here
    await client.quit(); // Close the connection when done
    } catch (error) {
    console.error(error);
  }
};

connectToRedis();
      `,
      phpCode: `
use Redis;
$client = new Redis();
$client->connect("{{ endpoint }}", {{ port }});
$client->auth("{{ password }}");
$client->set('foo', 'bar');
`,
      pythonCode:`
import redis
client = redis.StrictRedis(host="{{ endpoint }}", port={{ port }}, password="{{ password }}", ssl=True)
client.set('foo', 'bar')
`,
      goCode: `
package main

import (
    "github.com/go-redis/redis/v8"
    "context"
    "log"
)

func main() {
    ctx := context.Background()
    client := redis.NewClient(&redis.Options{
        Addr:     "{{ endpoint }}:{{ port }}",
        Password: "{{ password }}",
        DB:       0,
    })

    err := client.Set(ctx, "foo", "bar", 0).Err()
    if err != nil {
        log.Fatal(err)
    }
}`,
      rubyCode: `
require 'redis'
redis = Redis.new(host: "{{ endpoint }}", port: {{ port }}, password: "{{ password }}", ssl_params: { verify_mode: OpenSSL::SSL::VERIFY_NONE })
redis.set('foo', 'bar')`,
      javaCode:`
import redis.clients.jedis.Jedis;
import redis.clients.jedis.JedisPool;

public class RedisExample {
  public static void main(String[] args) {
    JedisPool pool = new JedisPool("{{ endpoint }}", {{ port }});
    Jedis jedis = pool.getResource();
    jedis.auth("{{ password }}");
    jedis.set("foo", "bar");
    jedis.close();
  }
}`,
      codeBlockTheme: 'idea',
      hidePassword: true,
      tabItem: ['Js', 'Php', 'Python','Go','Java','Ruby'],
      tabValue: 0,
      region: 'us-east-1',
      endpoint: 'sample.landofbytes.com',
      password: '**************',
      port: '30001',
      tlsEnabled: true,
      selectedLanguage: 'Js',
      username: 'default',
      showNotification: false,
    };
  },
  components: {
    CodeBlock,
    Prism,
  },
  computed: {
    redisUrl() {
      return `redis://${this.username}:${this.password}@${this.endpoint}:${this.port}`;
    },
    hiddenRedisUrl () {
      return `redis://${this.username}:******@${this.endpoint}:${this.port}`;
    },

    theme() {
      return useGlobalConfig().getGlobalConfig().colors
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    togglePasswordVisibility() {
      this.hidePassword = !this.hidePassword
    },
    showCopiedNotification() {
      // You can use a library for notifications, or create a custom notification component
      // For simplicity, let's assume you have a data property 'showNotification' in your component
      this.showNotification = true;

      // Hide the notification after 2 seconds (2000 milliseconds)
      setTimeout(() => {
        this.showNotification = false;
      }, 2000);
    },
    async fetchData() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'token': store.getters.token
        }
        const response = await axios.get('https://api.landofbytes.com/service/redis/detail', { headers });
        console.log(response);
        this.port = response.data.port
        this.region = response.data.region
        this.endpoint = response.data.endpoint
        this.tlsEnabled = response.data.tls
        this.password = response.data.password
        this.username = response.data.username
      } catch (e) {
        console.log(e)
      }
    },
    changeTab(index) {
      this.tabValue = index;
      this.selectedLanguage = this.tabItem[index];
    },
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.redisUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.showCopiedNotification();
    },
  },
};
</script>

<style scoped>
/*@use 'prismjs/themes/prism-coy.css';*/
.container {
  /*display: flex;*/

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--va-background);
  border-radius: 8px;
}

.card {
  background-color: var(--va-white);
  border: 1px solid var(--va-white);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.redis-url-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--va-primary);
  border-radius: 8px;
  color: #fff;
  margin-bottom: 20px; /* Adjust margin if necessary */
}
@media (max-width: 1059px) {
  .redis-url-container {
    display: block;

  }

}

.redis-url  {
  cursor: pointer;
  font-family: monospace;
  font-size: 16px;
}

.copy-button {
  cursor: pointer;
  color: #fff;
  background-color: var(--va-primary);
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  margin-top: 10px;
  width: -webkit-fill-available;
}

.copy-button:hover {
  background-color: var(--va-primary);
}

.table-responsive {
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.custom-table th,
.custom-table td {
  padding: 12px;
  text-align: center;
  /* Rest of your styles */
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
}

.tabs-container div {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.tabs-container div.active {
  background-color: var(--va-primary);
  color: #fff;
}

.connection-info {
  background-color: var(--va-divider);
  border-radius: 8px;
  padding: 20px;
}

.code-scroll {
  /*background-color: var(--va-warning);*/
  overflow-x: auto;
  max-width: 100%;
}
.div-center{
  align-items: center;
}

.code-block {
  /*background-color: var(--va-warning);*/
  border-radius: 8px;
  padding: 20px;
  white-space: pre-wrap;
  max-width: 100%;
}
.eye-icon {
  cursor: pointer;
  margin-left: 5px; /* Adjust margin if necessary */
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.text-color {
  color: var(--va-dark);
}
</style>
